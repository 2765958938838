<template>
  <v-container>
    <v-card class="pa-6">
      <v-card-title
        style="font-family: Kelly Slab !important"
        class="text-center text-h6 text-sm-h3 text-sm-h4 blue--text "
        >Algo aconteceu na negociação!</v-card-title
      >
      <v-card-text
        class="text-subtitle-1"
        style="font-family: Fjalla One, sans-serif !important;"
      >
        Por favor entre em contato com Nossa Central <br /><br />
        Segue nosso contato :
      </v-card-text>

      <v-card class="pa-3 blue--text" width="500">
        <h1 style="font-family: Kelly Slab !important">Fone</h1>
        <h3 class="indigo--text pb-8">{{ telefone }}</h3>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ErroPage",
  data() {
    return {
      telefone: "(21) 2206- 4883",
    };
  },
  async mounted() {
    await this.$store.dispatch("setMostraBotoes", {
      imprimir: false,
      voltar: false,
    });
    /*
    if (this.$store.state.credor_atual == "DI SANTINNI DSCARD") {
      this.telefone = "(21) 2206- 4886";
    } */
  },
};
</script>

<style></style>
